import { Injectable } from '@angular/core';
import { UUID } from "angular2-uuid";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public readonly relativeUrl: string = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  public readonly serviceId: number = 0;
  public readonly operatorId: number = 31;
  public readonly productId: number = 18654;
  public readonly apikey: string = 'f13ed656d0f0497dbae08eae106c9458';
  public readonly partnerRole: number = 4138;

  
  public readonly Plans$: any[] = [
    {
      id: 19162,
      title: 'Fan',
      subscription: 'Código x 1 Mes',
      price: 'S/ 15.00',
      benefits: [
        {
          abled: true,
          description: 'Sin publicidad.'
        },
        {
          abled: true,
          description: 'Acceso ilimitado al catálogo Crunchyroll.'
        },
        {
          abled: true,
          description: 'Mira estrenos 1 hora después que Japón.'
        },
        {
          abled: true,
          description: 'Disponible en 1 dispositivo a la vez.'
        },
        {
          abled: false,
          description: 'Visualiza sin conexión.'
        },
        {
          abled: false,
          description: '16% de dscto. sobre Plan Mensual (cargo al año).'
        }
      ]
    },
    {
      id: 19163,
      title: 'Fan',
      subscription: 'Código x 3 Meses',
      price: 'S/ 43.00',
      benefits: [
        {
          abled: true,
          description: 'Sin publicidad.'
        },
        {
          abled: true,
          description: 'Acceso ilimitado al catálogo Crunchyroll.'
        },
        {
          abled: true,
          description: 'Mira estrenos 1 hora después que Japón.'
        },
        {
          abled: true,
          description: 'Disponible en 1 dispositivo a la vez.'
        },
        {
          abled: false,
          description: 'Visualiza sin conexión.'
        },
        {
          abled: false,
          description: '16% de dscto. sobre Plan Mensual (cargo al año).'
        }
      ]
    }
  ];

  constructor() { }

  public bodyScroll(open: boolean) {
    open ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open');
  }

  public getQueryParams() {
    let result: any = {};
    let _params = new URLSearchParams(location.search) as any;
    for (let param of _params) { result[param[0]] = param[1] }
    return result;
  }

  public generateRandomId(): string {
    return UUID.UUID();
  }

  public generateDeviceId() {
    let user = JSON.parse(localStorage.u || '{}');

    if (user.d) {
      return user.d;
    } else {
      user.d = UUID.UUID();
      window.localStorage.u = JSON.stringify(user);
      return user.d;
    }
  }

  public generateSessionId() {
    let userSession = JSON.parse(sessionStorage.u || '{}');

    if (userSession.s) {
      return userSession.s;
    } else {
      userSession.s = UUID.UUID();
      window.sessionStorage.u = JSON.stringify(userSession);
      return userSession.s;
    }
  }

}