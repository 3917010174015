import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class PlansGuard implements CanActivate {

  constructor(
    private global: GlobalService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    !~this.global.Plans$.map(p => { return p.id }).indexOf(+route.params.planID) && this.router.navigate([''], { queryParamsHandling: 'merge' });
    return !!~this.global.Plans$.map(p => { return p.id }).indexOf(+route.params.planID);
  }

}
